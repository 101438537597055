import React from "react";
import img1 from "../assets/imgs/banner/Logo-Nosso-Crédito.png";
import img2 from "../assets/imgs/banner/losangulos_empilhados.png";
import img3 from "../assets/imgs/banner/31_anos.png";
import img4 from "../assets/imgs/banner/pref_Nova_Santa_Rita.png";
import img5 from "../assets/imgs/banner/divisor.png";

const AcxHeader = () => {
  return (
    <section id="header">
      <div className="container">
        <div className="grp1">
          <img
            src={img2}
            className="img2"
            alt="Desenhos da identidade da cidade em losangulos empilhados"
          />
          <img
            src={img1}
            className="img1"
            alt="Logotipo Nosso Crédito Santa Rita"
          />
        </div>

        <div className="grp2">
          <img
            src={img4}
            className="img4"
            alt="Arte em escrita da Prefeitura Nova Santa Rita"
          />
          <img src={img5} className="img5" alt="Linha separadora de conteúdo" />
          <img
            src={img3}
            className="img3"
            alt="Logo de comemoração dos 31 anos"
          />
        </div>
      </div>
    </section>
  );
};

export default AcxHeader;
