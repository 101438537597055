import React from "react";
import logoCred from "../assets/imgs/logo-w.png";
import logosB from "../assets/imgs/logos.png";

const BcxSectionA = () => {
  return (
    <section id="SectionA">
      <div className="container">
        <div className="text">
          <p>
            Nosso Crédito é uma solução para auxiliar microempreendedores
            individuais, microempresas e empresas de pequeno porte que estão
            localizadas no município de Nova Santa Rita.
          </p>
          <p>
            O objetivo é facilitar o acesso ao crédito além de fornecer
            capacitações.
          </p>
          <p>
            Um ecossistema de inovação em crédito assistido, criado pela união
            de esforços entre a Prefeitura de Nova Santa Rita, Sebrae, Sicredi e
            RS Garanti.
          </p>
        </div>

        <div className="logos">
          <img
            className="credLogo"
            src={logoCred}
            alt="Logotipo Nosso Crédito Nova Santa Rita"
          />
          <img
            className="parceiros"
            src={logosB}
            alt="Logotipos Prefeitura de Nova Santa Rita, Sicredi, Sebrae, RS Garanti"
          />
        </div>
      </div>
    </section>
  );
};

export default BcxSectionA;
