import React from "react";

const BcxSectionG = () => {
  return (
    <section id="sectionG">
      <div className="container">
        <h2 className="title">
          <strong>Dúvidas Frequentes</strong> <br />
          Sobre o Programa
        </h2>

        <div className="duvida">
          <h3>
            Sou associado, posso ir até a minha agência solicitar o crédito?
          </h3>
          <p>
            As demandas serão recebidas através do formulário dessa página.
            <br />
            As cooperativas de crédito participantes e a RS Garanti farão a
            análise prévia e retornarão o contato.
          </p>
        </div>

        <div className="duvida">
          <h3>A Garantidora irá cobrar alguma tarifa?</h3>
          <p>
            Sim, O valor está informado no hotsite do programa, e também poderá
            contatar a RS Garanti pelo fone: (54) 3021.0400.
          </p>
        </div>

        <div className="duvida">
          <h3>
            Tenho dívidas com tributos municipais, posso acessar o crédito?
          </h3>
          <p>
            O crédito também pode ser usado para fazer este tipo de
            regularização,
            <br />
            mas para isso será necessário procurar a prefeitura antes de
            solicitar o crédito.
          </p>
        </div>

        <div className="duvida">
          <h3>Serão verificados restritivos?</h3>
          <p>
            Sim, a análise de crédito será feita normalmente junto as
            cooperativas participantes e a RS Garanti.
          </p>
        </div>

        <div className="duvida">
          <h3>
            Para ter acesso ao crédito, preciso abrir uma conta em cooperativa
            de crédito?
          </h3>
          <p>
            Sim. Se você ainda não é associado à uma cooperativa de crédito
            participante, será necessário fazer a abertura de uma conta corrente
            para ter acesso aos benefícios dos programa.
          </p>
        </div>
        <h4>
          Para mais informações, entre em contato com a RS Garanti através do
          fone 54 3021 0400
        </h4>
      </div>
    </section>
  );
};

export default BcxSectionG;
