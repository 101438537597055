import React from "react";
import { useForm } from "react-hook-form";
import PjForm from "./form_components/PjForm";
import PfForm from "./form_components/PfForm";
import CreditoForm from "./form_components/CreditoForm";
import SocioForm from "./form_components/SocioForm";
import TermosForm from "./form_components/TermosForm";
import UploadForm from "./form_components/UploadForm";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import sendEmail from "../services/send-email";
import LoadingButton from "@mui/lab/LoadingButton";

const BcxSectionForm = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const [snkOpen, setSnkOpen] = React.useState(false);
  const [snkMsg, setSnkMsg] = React.useState("");

  const showSnackbar = (msg) => {
    setSnkMsg(msg);
    setSnkOpen(true);
    window.setTimeout(() => {
      setSnkOpen(false);
    }, 6000);
  };

  const onSubmit = (data) => {
    setIsLoading(true)
    const currencyToNumber = (value) => {
      return Number(value.replace(/[^\d,]/g, '').replace(',', '.')).toFixed(2);
    }

    const newSocios = [];
    newSocios.push({
      Cpf: data.Cpf.replace(/\D/g, ""),
      Nome: data.Nome,
      Email: data.Email,
      Telefone: data.Telefone,
      DataDeNascimento: data.DataDeNascimento,
      NomeDaMae: data.NomeDaMae,
      Rg: data.Rg.replace(/\D/g, ""),
      Sexo: Number(data.Sexo),
      Complemento: data.Complemento,
      ComplementoConjuge: data.ComplementoConjuge,
      Naturalidade: data.Naturalidade,
      ProfissaoConjuge: data.ProfissaoConjuge,
      RendaConjuge: data.RendaConjuge,
      Cep: data.Cep.replace(/\D/g, ""),
      Endereco: data.Endereco,
      Bairro: data.Bairro,
      Estado: data.Estado,
      Cidade: data.Cidade,
      Participacao: data.Participacao,
      EstadoCivil: Number(data.EstadoCivil),
      CpfConjuge: data.CpfConjuge.replace(/\D/g, ""),
      NomeConjuge: data.NomeConjuge,
      EmailConjuge: data.EmailConjuge,
      TelefoneConjuge: data.TelefoneConjuge,
      DataDeNascimentoConjuge: data.DataDeNascimentoConjuge,
      NomeDaMaeConjuge: data.NomeDaMaeConjuge,
      RgConjuge: data.RgConjuge,
      SexoConjuge: Number(data.SexoConjuge),
      NaturalidadeConjuge: data.NaturalidadeConjuge,
      CepConjuge: data.CepConjuge.replace(/\D/g, ""),
      EnderecoConjuge: data.EnderecoConjuge,
      BairroConjuge: data.BairroConjuge,
      EstadoConjuge: data.EstadoConjuge,
      CidadeConjuge: data.CidadeConjuge,
    });

    const socios = watch("socios");
    for (let i = 0; i < socios?.length || 0; i++) {
      newSocios.push({
        Cpf: data.socios[i].Cpf.replace(/\D/g, ""),
        Nome: data.socios[i].Nome,
        Email: data.socios[i].Email,
        Telefone: data.socios[i].Telefone,
        DataDeNascimento: data.socios[i].DataDeNascimento,
        NomeDaMae: data.socios[i].NomeDaMae,
        Rg: data.socios[i].Rg.replace(/\D/g, ""),
        Sexo: Number(data.socios[i].Sexo),
        Naturalidade: data.socios[i].Naturalidade,
        Cep: data.socios[i].Cep.replace(/\D/g, ""),
        Endereco: data.socios[i].Endereco,
        Complemento: data.socios[i].Complemento,
        ComplementoConjuge: data.socios[i].ComplementoConjuge,
        ProfissaoConjuge: data.socios[i].ProfissaoConjuge,
        RendaConjuge: data.socios[i].RendaConjuge,
        Bairro: data.socios[i].Bairro,
        Estado: data.socios[i].Estado,
        Cidade: data.socios[i].Cidade,
        Participacao: data.socios[i].Participacao,
        EstadoCivil: Number(data.socios[i].EstadoCivil),
        CpfConjuge: data.socios[i].CpfConjuge.replace(/\D/g, ""),
        NomeConjuge: data.socios[i].NomeConjuge,
        EmailConjuge: data.socios[i].EmailConjuge,
        TelefoneConjuge: data.socios[i].TelefoneConjuge,
        DataDeNascimentoConjuge: data.socios[i].DataDeNascimentoConjuge,
        NomeDaMaeConjuge: data.socios[i].NomeDaMaeConjuge,
        RgConjuge: data.socios[i].RgConjuge.replace(/\D/g, ""),
        SexoConjuge: Number(data.socios[i].SexoConjuge),
        NaturalidadeConjuge: data.socios[i].NaturalidadeConjuge,
        CepConjuge: data.socios[i].CepConjuge.replace(/\D/g, ""),
        EnderecoConjuge: data.socios[i].EnderecoConjuge,
        BairroConjuge: data.socios[i].BairroConjuge,
        EstadoConjuge: data.socios[i].EstadoConjuge,
        CidadeConjuge: data.socios[i].CidadeConjuge,
      });
    }

    const body = {
      Cnpj: data.Cnpj.replace(/\D/g, ""),
      RazaoSocial: data.RazaoSocial,
      TelefoneDaEmpresa: data.TelefoneDaEmpresa,
      CepDaEmpresa: data.CepDaEmpresa.replace(/\D/g, ""),
      EnderecoDaEmpresa: data.EnderecoDaEmpresa,
      BairroDaEmpresa: data.BairroDaEmpresa,
      EstadoDaEmpresa: data.EstadoDaEmpresa,
      CidadeDaEmpresa: data.CidadeDaEmpresa,
      EmailDaEmpresa: data.EmailDaEmpresa,
      FaturamentoAnual: currencyToNumber(data.FaturamentoAnual),
      PorteDaEmpresa: data.PorteDaEmpresa,
      AtividadeDaEmpresa: Number(data.AtividadeDaEmpresa),
      AssociadoAoSicredi: Number(data.AssociadoAoSicredi),
      Agencia: data.Agencia,
      InstituicaoFinanceira : "5",
      ValorCreditoPretendido: currencyToNumber(data.ValorCreditoPretendido),
      NumeroDeParcelas: data.NumeroDeParcelas,
      Carencia: data.Carencia,
      FinalidadeDoCredito: data.FinalidadeDoCredito,
      NumeroAtualFuncionario: data.NumeroAtualFuncionario,
      ProvaveisEmpregos: data.ProvaveisEmpregos,
      AutorizaLgpd: data.AutorizaLgpd,
      AutorizaSicredi: data.AutorizaSicredi,
      AutorizaConsultoriaSebrae: data.AutorizaConsultoriaSebrae,
      AutorizaCompartilhaDados: data.AutorizaCompartilhaDados,
      Base64DoArquivo: data.Base64DoArquivo,
      ExtensaoDoArquivo: ".pdf",
      Socios: newSocios,
    };
    const url =
      process.env.REACT_APP_GARANTIMAIS_API_URL +
      "Cadastro/Associados/NovaSantaRitaRsGaranti";
    const key = process.env.REACT_APP_GARANTIMAIS_API_KEY;

    if (sum !== 100) {
      setIsLoading(false);
      showSnackbar(
        `A soma de participação dos sócios não pode ser diferente de 100%, valor total está em: ${sum}%`
      );
      return;
    }
    // setIsLoading(false);
    // console.log(body);

    axios
      .post(url, body, {
        headers: {
          Authorization: key,
        },
      })
      .then(() => {
        // TODO: desabilitar loading
        setIsLoading(false);
        showSnackbar("Seus dados foram enviados com sucesso!");
        sendEmail(data, socios);
      })
      .catch((error) => {
        // TODO: desabilitar loading
        setIsLoading(false);
        const erroMessage =
          error.response?.data?.ErrorMessage || "Aconteceu algo inesperado";
        showSnackbar(erroMessage);
      });
  };

  //soma das participações
  const sociedade = watch("Participacao");
  const socios = watch("socios");

  let sum = 0;

  sum += Number(sociedade);

  for (let i = 0; i < socios?.length || 0; i++) {
    sum += Number(socios[i].Participacao);
  }

  return (
    <section id="sectionForm">
      <div className="formHeader">
        <h2 className="fHeaderTitle">
          Solicite o crédito
          <br />
          <strong>para sua empresa!</strong>
        </h2>
        <h4 className="fHeaderDescription">
          Aproveite a chance de construir novos projetos e aprimorar os seus
          negócios.
          <br />
          <strong>Preencha o formulário para receber mais informações.</strong>
        </h4>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PjForm
          register={register}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
        <UploadForm register={register} setValue={setValue} errors={errors} />
        <PfForm
          register={register}
          watch={watch}
          errors={errors}
          setValue={setValue}
        />
        <SocioForm
          register={register}
          watch={watch}
          control={control}
          errors={errors}
          setValue={setValue}
        />
        <CreditoForm
          register={register}
          errors={errors}
          setValue={setValue}
          watch={watch}
        />
        <TermosForm register={register} errors={errors} />
        <Snackbar
          open={snkOpen}
          message={snkMsg}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          variant="filled"
        />

        <div className="btnSend">
          <LoadingButton loading={isLoading} type="submit" variant="contained">
            Enviar formulário
          </LoadingButton>
        </div>
      </form>
    </section>
  );
};

export default BcxSectionForm;
