import React from "react";
import Sicredi from "../assets/imgs/logo-1.jpg";
import Sebrae from "../assets/imgs/logo-2.jpg";
import RsGaranti from "../assets/imgs/logo-3.jpg";
import SantaMaria from "../assets/imgs/logo-7.jpg";

const CcxFooter = () => {
  return (
    <section id="footer">
      <div className="container">
        <div className="logos">
          <a
            href="https://novasantarita.atende.net/cidadao"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={SantaMaria} alt="Logo da Prefeitura de Nova Santa Rita" />
          </a>

          <a
            href="https://www.sicrediourobranco.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Sicredi} alt="Logo da Sicredi" />
          </a>

          <a
            href="https://www.rsgaranti.org.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={RsGaranti} alt="Logo da RS Garanti" />
          </a>

          <a
            href="https://www.sebraers.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Sebrae} alt="Logo do Sebrae" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default CcxFooter;
